export const ATTACHMENT_TYPES = [
  {
    label: "Project Plan/Checklist",
    value: "Project Plan/Checklist",
  },
  {
    label: "Innovation Plan",
    value: "Innovation Plan",
  },
  {
    label: "Marketing Plan",
    value: "Marketing Plan",
  },
  {
    label: "Proof of Cost",
    value: "Proof of Cost",
  },
  {
    label: "Migration Completion report",
    value: "Migration Completion report",
  },
  {
    label: "Assess Analysis Report",
    value: "Assess Analysis Report",
  },
  {
    label: "Well Architected Review Report",
    value: "Well Architected Review Report",
  },
  {
    label: "Success Criteria Report",
    value: "Success Criteria Report",
  },
  {
    label: "Customer Sign Off Template",
    value: "Customer Sign Off Template",
  },
  {
    label: "Partner Sign Off Template",
    value: "Partner Sign Off Template",
  },
  {
    label: "Customer Satisfaction Survey (CSAT)",
    value: "Customer Satisfaction Survey (CSAT)",
  },
  {
    label: "Migration Capability Review",
    value: "Migration Capability Review",
  },
  {
    label: "AWS Pricing Calculator",
    value: "AWS Pricing Calculator",
  },
  { label: "Other", value: "Other" },
];
