import Config from "config";
import { getFundRequestId } from "../../common/helper";
import {
  getPartnerAccountId,
  getSpmsId,
  isAuthTokenValid,
} from "../../../../shared/util/services/auth/AuthService";

import {
  b64EncodeUnicode,
  deleteData,
  getData,
  postData,
  putData,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";
import { generateIdempotencyToken } from "../../../../shared/util/services/data/GenerateIdempotencyToken";
import { FundRequestHistoryCategory } from "../../../../shared/util/constants/fundRequestStatusType";
import { getPartnerScorecardId } from "../auth/AuthService";

export const PING_ENDPOINT = `${Config.FUNDING_ENDPOINT}/ping`;

export const API_V1_END_POINT = `${Config.FUNDING_ENDPOINT}/v1`;

export const FILE_END_POINT = `${API_V1_END_POINT}/files`;

export const FUND_REQUEST_ENDPOINT = `${API_V1_END_POINT}/fund-requests`;

export const CASH_CLAIM_ENDPOINT = `${API_V1_END_POINT}/cash-claims`;

export const CREDIT_ENDPOINT = `${API_V1_END_POINT}/credit-codes`;

export const createFundRequest = async (body) => {
  // Idempotency token is generated within program select component
  return await postData({
    endpoint: FUND_REQUEST_ENDPOINT,
    body: b64EncodeUnicode(JSON.stringify(body)),
  });
};

export const updateEligibility = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/template`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "eligibility" }),
        ...body,
      })
    ),
  });
};

export const updateTemplateInformation = async (body) => {
  const data = {
    annualRunRate: body["arr"],
  };
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/template`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "update-arr",
        }),
        ...data,
      })
    ),
  });
};

// Update fund request api
export const updateFundRequestInformation = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/details`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "details" }),
        ...body,
      })
    ),
  });
};

export const updateOpportunityInformation = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/opportunity`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "opportunity" }),
        ...body,
      })
    ),
  });
};

export const updateProjectInformation = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/project`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "project" }),
        ...body,
      })
    ),
  });
};

export const updateMigrationWorkloads = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/migrations`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "migrations" }),
        ...body,
      })
    ),
  });
};

export const updatePublicSectorDetails = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/public-sector`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "public-sector-details",
        }),
        ...body,
      })
    ),
  });
};

export const updateCashRequestInformation = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/cash-request`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "cash-request",
        }),
        ...body,
      })
    ),
  });
};

export const updateCreditRequestDetails = async (body) => {
  const payload = {
    valuePerCode: body["valuePerCode"],
    numberOfCodes: body["numberOfCodes"],
    currency: body["currency"],
    requestedCreditAmount:
      body["requestedCreditAmountEditable"] || body["requestedCreditAmount"],
    totalCostOfActivity: body["totalCostOfActivity"],
    mdfCreditActivityType: body["mdfCreditActivityType"],
  };
  if (body["budgetId"]) {
    payload["budgetId"] = body["budgetId"];
  }
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/credit-request`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "credit-request",
        }),
        ...payload,
      })
    ),
  });
};

export const submitFundRequest = async () => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/submit`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "submit",
        }),
      })
    ),
  });
};

export const terminateFundRequest = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/terminate`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "terminate",
        }),
        ...body,
      })
    ),
  });
};

export const deleteFundRequest = async (id, body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${id || getFundRequestId()}/delete`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          fundRequestId: id || getFundRequestId(),
          action: "delete",
        }),
        ...body,
      })
    ),
  });
};

export const recallFundRequest = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/recall`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "recall",
        }),
        ...body,
      })
    ),
  });
};

export const changeFundRequestOwner = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/owner`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "owner",
        }),
        ...body,
      })
    ),
  });
};

export const updateCashClaim = async (body, cashClaimId) => {
  return await putData({
    endpoint: `${CASH_CLAIM_ENDPOINT}/${cashClaimId}/actual`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "cash-claim-update",
        }),
        ...body,
      })
    ),
  });
};

export const submitCashClaim = async (body, cashClaimId) => {
  return await putData({
    endpoint: `${CASH_CLAIM_ENDPOINT}/${cashClaimId}/submit`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "cash-claim-submit",
        }),
        ...body,
      })
    ),
  });
};

export const submitFundRequestExtension = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/extend`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "extend",
        }),
        ...body,
      })
    ),
  });
};

// Get API
export const getPing = async () => {
  return await getData({ endpoint: PING_ENDPOINT });
};

export const getFundRequest = async (fundRequestId) => {
  return await getData({
    endpoint: FUND_REQUEST_ENDPOINT,
    routeParam: fundRequestId,
  });
};

export const getFundRequestComments = async ({
  fundRequestId = getFundRequestId(),
  nextPageToken,
}) => {
  const queryParam = [
    `nextPageToken=${nextPageToken && btoa(JSON.stringify(nextPageToken))}`,
    `category=${FundRequestHistoryCategory.EXTERNAL_APPROVAL}`,
  ];
  return await getData({
    endpoint: FUND_REQUEST_ENDPOINT,
    routeParam: `${fundRequestId}/history`,
    queryParam,
  });
};

export const getFundRequests = async (nextPageToken) => {
  const queryParam = [
    `nextPageToken=${nextPageToken && btoa(JSON.stringify(nextPageToken))}`,
  ];
  return await getData({ endpoint: FUND_REQUEST_ENDPOINT, queryParam });
};

export const getCountries = async () => {
  return await getData({ endpoint: API_V1_END_POINT, routeParam: "countries" });
};

export const getCurrencies = async () => {
  return await getData({
    endpoint: API_V1_END_POINT,
    routeParam: "currencies",
  });
};

export const getCredits = async (nextPageToken) => {
  const queryParam = [
    `nextPageToken=${nextPageToken && btoa(JSON.stringify(nextPageToken))}`,
  ];
  return await getData({
    endpoint: CREDIT_ENDPOINT,
    routeParam: "credits",
    queryParam,
  });
};

export const getPartnerScorecard = async () => {
  const queryParam = [
    `scorecardId=${getPartnerScorecardId()}`,
    `accountId=${getPartnerAccountId()}`,
  ];
  return await getData({
    endpoint: API_V1_END_POINT,
    routeParam: "partner-scorecard",
    queryParam,
  });
};

export const getBudgets = async ({
  fundingTemplates,
  fundingTypes,
  budgetRecordTypes,
}) => {
  const queryParam = [
    `scorecardId=${getPartnerScorecardId()}`,
    `accountId=${getPartnerAccountId()}`,
    `fundingTemplates=${fundingTemplates}`,
    `fundingTypes=${fundingTypes}`,
    `budgetRecordTypes=${budgetRecordTypes}`,
  ];
  return await getData({
    endpoint: API_V1_END_POINT,
    routeParam: "partner-scorecard",
    queryParam,
  });
};

export const getUploadFileUrl = async (body, fund_claim_id) => {
  return await putData({
    endpoint: `${FILE_END_POINT}/upload/${getFundRequestId()}/url?fund_claim_id=${
      fund_claim_id || ""
    }`,
    body: b64EncodeUnicode(JSON.stringify(body)),
  });
};

export const uploadFile = async (endpoint, body, fileId) => {
  try {
    if (await isAuthTokenValid()) {
      return await fetch(endpoint, {
        method: "PUT",
        headers: { "x-amz-tagging": `FileId=${fileId}` },
        body,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const getFundRequestAttachments = async (nextPageToken) => {
  const queryParam = [
    `nextPageToken=${nextPageToken && btoa(JSON.stringify(nextPageToken))}`,
  ];
  return await getData({
    endpoint: `${FILE_END_POINT}/file-list/fund-request`,
    routeParam: getFundRequestId(),
    queryParam,
  });
};

//TODO: This is not used, we just get all attachments and filter client-side.
// export const getFundClaimAttachments = async (nextPageToken, fundClaimId) => {
//   const queryParam = [
//     `fund_claim_id=${fundClaimId}`,
//     `nextPageToken=${nextPageToken && btoa(JSON.stringify(nextPageToken))}`,
//   ];
//   return await getData(
//     `${FILE_END_POINT}`,
//     getHeader(),
//     "file-list/fund-claim",
//     queryParam
//   );
// };

export const deleteFile = async (fildId) => {
  return await deleteData({ endpoint: `${FILE_END_POINT}/${fildId}/delete` });
};

export const attachFileToFundRequest = async ({ body, fundRequestId }) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/attach`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "attach-to-fund-request",
        }),
        ...body,
      })
    ),
  });
};

export const attachFileToCashClaim = async ({
  body,
  fundRequestId,
  fundClaimId,
}) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/claim/${fundClaimId}/attach`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "attach-to-cash-claim",
        }),
        ...body,
      })
    ),
  });
};

export const detachFileFromFundRequest = async ({ body, fundRequestId }) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/detach`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "detach-from-fund-request",
        }),
        ...body,
      })
    ),
  });
};

export const detachFileFromCashClaim = async ({
  body,
  fundRequestId,
  fundClaimId,
}) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/claim/${fundClaimId}/detach`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "detach-from-cash-claim",
        }),
        ...body,
      })
    ),
  });
};

export const getDownloadUrl = async (fileId) => {
  const queryParam = [`fileId=${fileId}`];
  return await getData({
    endpoint: FILE_END_POINT,
    routeParam: "download/url",
    queryParam,
  });
};

export const loadCountries = async () => {
  let countries = {};
  try {
    const response = await retryApiCall({ callApi: getCountries() });
    response.items.sort((a, b) => a.name > b.name);
    countries = response.items.map((item) => {
      const countryName = item.name;
      const countryCode = item.countryISO2Code;
      return { label: countryName, value: countryCode };
    });
  } catch (err) {
    console.error(err);
  }
  return countries;
};

export const loadCurrencies = async () => {
  let currencies = {};
  try {
    const response = await retryApiCall({ callApi: getCurrencies() });
    response.items.sort((a, b) => a.name > b.name);
    currencies = response.items.map((item) => {
      return { label: item.name, value: item.value };
    });
  } catch (err) {
    console.error(err);
  }
  return currencies;
};

export const loadOpportunities = async (
  timeRange,
  fundingProgram,
  migrationPhase,
  fundingSubProgram,
  isMapLite = null
) => {
  let opportunities = [];
  let token = "";
  let response;

  while (token != null) {
    try {
      let queryParam = [
        `spmsId=${getSpmsId()}`,
        `nextPageToken=${token && btoa(JSON.stringify(token))}`,
        `timeRange=${timeRange}`,
        `fundingProgram=${fundingProgram}`,
        `migrationPhase=${migrationPhase}`,
        `fundingSubProgram=${encodeURIComponent(fundingSubProgram)}`,
        `isMapLite=${isMapLite}`,
      ];

      response = await retryApiCall({
        callApi: await getData({
          endpoint: API_V1_END_POINT,
          routeParam: "opportunities",
          queryParam,
        }),
      });

      if (response) {
        opportunities = opportunities.concat(response.items);
        token = response.nextPageToken;
      }
    } catch (err) {
      console.error(err);
      return;
    }
  }
  return opportunities;
};

export const getTemplateEligibility = async (type = "") => {
  const queryParam = type ? [`name=${type}`] : "";
  return await getData({
    endpoint: API_V1_END_POINT,
    routeParam: "templateEligibility",
    queryParam,
  });
};

export const updateConsentInformation = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/consent`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "update-consent",
        }),
        ...body,
      })
    ),
  });
};
