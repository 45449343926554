import React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import { useCollection } from "@amzn/awsui-collection-hooks";
import TableEmptyState from "../../../../common/TableEmptyState";
import TableNoMatchState from "../../../../common/TableNoMatchState";
import TableHeader from "./TableHeader/TableHeader";
import { columnDefinitions, contentDisplay } from "./config";
import { Alert } from "@amzn/awsui-components-react";
import { PropTypes } from "prop-types";

export const SCATableContainer = ({ data, dataInput, budgets }) => {
  // State variables to track current state of table
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [selectedBudget, setSelectedBudget] = React.useState({});
  const [preferences, setPreferences] = React.useState({
    pageSize: 10,
    contentDisplay: contentDisplay,
  });

  // Used to display currently selected budget in a banner above the table
  React.useEffect(() => {
    if ("budgetId" in data && data["budgetId"]) {
      setSelectedBudget(
        budgets.find((item) => item.budgetId === data["budgetId"])
      );
    }
  }, [data]);

  // Filtering, sorting, pagination
  const {
    items,
    actions,
    collectionProps,
    filterProps,
    paginationProps,
    sortProps,
  } = useCollection(budgets, {
    filtering: {
      empty: <TableEmptyState resourceName="budgets" />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => actions.setFiltering("")}
          resourceName="budgets"
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: { sortingField: "name" },
        isDescending: false,
      },
    },
  });

  // Updates budget in data object to send to the backend
  const updateBudget = (budgetId) => {
    let currentValues = {};
    currentValues["budgetId"] = budgetId;
    data["budgetId"] = budgetId;
    dataInput(currentValues);
  };

  // The table component
  return (
    <div>
      {/* Enables or disables the banner if a budget is selected */}
      <div hidden={!data["budgetId"]}>
        <Alert statusIconAriaLabel="" header="SCA selected">
          Budget Name - {selectedBudget.name}
        </Alert>
        <br />
      </div>
      <React.Fragment>
        <Table
          {...collectionProps}
          selectedItems={selectedItems}
          onSelectionChange={({ detail }) => {
            setSelectedItems(detail.selectedItems);
            setSelectedBudget(detail.selectedItems[0]);
            updateBudget(detail.selectedItems[0].budgetId);
          }}
          columnDefinitions={columnDefinitions}
          columnDisplay={preferences.contentDisplay}
          enableKeyboardNavigation={true}
          items={[...items]}
          loadingText="Loading Strategic Collaboration Agreements"
          selectionType="single"
          trackBy="budgetId"
          filter={
            <TextFilter
              {...filterProps}
              filteringPlaceholder="Find SCA Budgets"
            />
          }
          pagination={<Pagination {...paginationProps} />}
          {...sortProps}
          preferences={
            <CollectionPreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              contentDisplayPreference={{
                options: columnDefinitions.map(({ id, header }) => ({
                  id,
                  label: header,
                })),
              }}
              pageSizePreference={{
                title: "Page size",
                options: [
                  { value: 5, label: "5 agreements" },
                  { value: 10, label: "10 agreements" },
                ],
              }}
            />
          }
          header={
            <TableHeader
              totalItemsCount={budgets.length}
              setSelectedItems={setSelectedItems}
              setSelectedBudget={setSelectedBudget}
              updateBudget={updateBudget}
            />
          }
        />
      </React.Fragment>
      <br />
    </div>
  );
};

SCATableContainer.propTypes = {
  data: PropTypes.object,
  dataInput: PropTypes.func,
  budgets: PropTypes.arrayOf(PropTypes.object),
};
