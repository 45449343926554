import { v4 as uuid } from "uuid";
import { SECTION, SECTION_NAME } from "../../constants/section";
import { UiValueAdapterFactory } from "./UiValueAdapterFactory";
import { uiToApiMap } from "./adapter";
import { generateMultiSectionKey } from "../../common/generateMultiSectionKey";

const opportunityInformationApiToUiSectionDataAdapter = ({
  sectionTitle,
  program,
  data,
  schema,
  filterFields,
}) => {
  const section = schema[sectionTitle];

  const sectionName = section.name;
  const oppApiSectionName = uiToApiMap[SECTION_NAME.OPPORTUNITY_INFORMATION];
  const { fields, fieldToIdMap, initializedData } = generateFieldMetadata({
    data: data[oppApiSectionName],
    section,
    sectionName,
    program,
    filterFields,
    apiKey: "opportunityDetail",
  });

  initializedData["aceOpportunityId"] =
    data[oppApiSectionName]["aceOpportunityId"];

  let fieldTemplate = {
    [sectionName]: {
      ...section,
      fields,
    },
  };

  return {
    fieldTemplate,
    fieldToIdMap,
    initializedData,
  };
};

const projectInformationApiToUiSectionDataAdapter = ({
  sectionTitle,
  program,
  data,
  schema,
  filterFields,
}) => {
  let newFieldTemplate = {};
  let newFieldToIdMap = {};
  let newInitializedData = {};

  const section = schema[sectionTitle];

  const { fields, fieldToIdMap, initializedData } = generateFieldMetadata({
    data,
    section,
    sectionName: SECTION_NAME.PROJECT_INFORMATION,
    program,
    filterFields,
  });

  newFieldTemplate[SECTION_NAME.PROJECT_INFORMATION] = { ...section, fields };
  newFieldToIdMap = { ...newFieldToIdMap, ...fieldToIdMap };
  newInitializedData = { ...newInitializedData, ...initializedData };

  const oppApiSectionName = uiToApiMap[SECTION_NAME.OPPORTUNITY_INFORMATION];
  const wwps =
    data && data[oppApiSectionName] && data[oppApiSectionName]["wwps"];

  if (wwps) {
    const pubSecSection = schema[SECTION.PUBLIC_SECTOR_DETAILS];
    const fieldMetadata = generateFieldMetadata({
      data,
      section: pubSecSection,
      sectionName: SECTION_NAME.PUBLIC_SECTOR_DETAILS,
      program,
      filterFields,
    });

    newFieldTemplate[SECTION_NAME.PUBLIC_SECTOR_DETAILS] = {
      ...pubSecSection,
      fields: fieldMetadata["fields"],
    };
    newFieldToIdMap = { ...newFieldToIdMap, ...fieldMetadata["fieldToIdMap"] };
    newInitializedData = {
      ...newInitializedData,
      ...fieldMetadata["initializedData"],
    };
  }

  return {
    fieldTemplate: newFieldTemplate,
    fieldToIdMap: newFieldToIdMap,
    initializedData: newInitializedData,
  };
};

const migrationWorkloadsApiToUiSectionDataAdapter = ({
  sectionTitle,
  program,
  data,
  schema,
  filterFields,
}) => {
  const section = schema[sectionTitle];
  const migrationsApiKey = uiToApiMap[SECTION_NAME.MIGRATION_DETAILS];
  const workloadsApiKey = uiToApiMap[SECTION_NAME.MIGRATION_WORKLOADS];
  const workloadData =
    (data &&
      data[migrationsApiKey] &&
      data[migrationsApiKey][workloadsApiKey]) ||
    [];

  const numOfWorkloads = workloadData.length > 0 ? workloadData.length : 1;
  // Create an array from 1...numOfWorkloads
  // Start at 1 or else migration details will be empty when no data present.
  // This is how we generate our key
  const workloads = [...Array(numOfWorkloads + 1).keys()].slice(1);

  let newFieldTemplate = {};
  let newFieldToIdMap = {};
  let newInitializedData = {};

  workloads.map((index) => {
    const sectionName = generateMultiSectionKey({
      index,
      sectionName: SECTION_NAME.MIGRATION_WORKLOADS,
    });

    const { fields, fieldToIdMap, initializedData } = generateFieldMetadata({
      data: workloadData[index - 1],
      section,
      sectionName,
      apiKey: SECTION_NAME.MIGRATION_WORKLOADS,
      program,
      filterFields,
    });

    newFieldTemplate[sectionName] = { ...section, fields };
    newFieldToIdMap = { ...newFieldToIdMap, ...fieldToIdMap };
    newInitializedData = { ...newInitializedData, ...initializedData };
  });

  return {
    fieldTemplate: newFieldTemplate,
    fieldToIdMap: newFieldToIdMap,
    initializedData: newInitializedData,
    numOfAdditionalSections: numOfWorkloads,
  };
};

const migrationDetailsApiToUiSectionDataAdapter = ({
  program,
  data,
  schema,
  filterFields,
}) => {
  let newFieldTemplate = {};
  let newFieldToIdMap = {};
  let newInitializedData = {};

  const migrationDetailsMetadata = defaultApiToUiSectionDataAdapter({
    sectionTitle: SECTION.MIGRATION_DETAILS,
    program,
    data,
    schema,
    filterFields,
  });

  newFieldTemplate = {
    ...newFieldTemplate,
    ...migrationDetailsMetadata["fieldTemplate"],
  };
  newFieldToIdMap = {
    ...newFieldToIdMap,
    ...migrationDetailsMetadata["fieldToIdMap"],
  };
  newInitializedData = {
    ...newInitializedData,
    ...migrationDetailsMetadata["initializedData"],
  };

  const migrationWorkloadMetadata = migrationWorkloadsApiToUiSectionDataAdapter(
    {
      sectionTitle: SECTION.MIGRATION_WORKLOADS,
      program,
      data,
      schema,
      filterFields,
    }
  );

  newFieldTemplate = {
    ...newFieldTemplate,
    ...migrationWorkloadMetadata["fieldTemplate"],
  };
  newFieldToIdMap = {
    ...newFieldToIdMap,
    ...migrationWorkloadMetadata["fieldToIdMap"],
  };
  newInitializedData = {
    ...newInitializedData,
    ...migrationWorkloadMetadata["initializedData"],
  };

  return {
    fieldTemplate: newFieldTemplate,
    fieldToIdMap: newFieldToIdMap,
    initializedData: newInitializedData,
    numOfAdditionalSections:
      migrationWorkloadMetadata["numOfAdditionalSections"],
  };
};

const cashRequestApiToUiSectionDataAdapter = ({
  sectionTitle,
  program,
  data,
  schema,
  filterFields,
}) => {
  const section = schema[sectionTitle];

  let newFieldTemplate = {};
  let newFieldToIdMap = {};
  let newInitializedData = {};

  const { fields, fieldToIdMap, initializedData } = generateFieldMetadata({
    data,
    section,
    sectionName: SECTION_NAME.CASH_REQUEST_INFORMATION,
    program,
    filterFields,
  });

  newInitializedData = {
    ...newInitializedData,
    ...(data.cashRequest?.cashBudgetInfo?.budgetId && {
      budgetId: data.cashRequest.cashBudgetInfo.budgetId,
    }),
  };

  newFieldTemplate[SECTION_NAME.CASH_REQUEST_INFORMATION] = {
    ...section,
    fields,
  };
  newFieldToIdMap = { ...newFieldToIdMap, ...fieldToIdMap };
  newInitializedData = { ...newInitializedData, ...initializedData };

  const cashRequestApiKey = uiToApiMap[SECTION_NAME.CASH_REQUEST_INFORMATION];
  const cashRequest =
    data && data[cashRequestApiKey] && data[cashRequestApiKey]["cashClaims"];
  let cashClaimKeys = cashRequest && Object.keys(cashRequest);

  const numOfCashClaims = cashClaimKeys.length > 0 ? cashClaimKeys.length : 1;

  let cashClaims = [...Array(numOfCashClaims + 1).keys()].slice(1);
  if (cashClaimKeys.length > 0) {
    cashClaims = cashClaimKeys.map((key) => {
      return parseInt(key.split("-").pop());
    });
  }
  cashClaims.map((cashClaimKey, index) => {
    // Cash Claim Plan
    const plansSectionName = generateMultiSectionKey({
      index: cashClaimKey,
      sectionName: SECTION_NAME.CASH_CLAIM_PLANS,
    });

    const plansSection = schema[SECTION.CASH_CLAIM_PLANS];

    const planData =
      (cashRequest &&
        cashClaimKeys[index] &&
        cashRequest[cashClaimKeys[index]] &&
        cashRequest[cashClaimKeys[index]]["plan"]) ||
      {};

    planData["fundClaimId"] =
      cashClaimKeys.length > 0
        ? cashClaimKeys[index]
        : `${data["fundRequestId"]}-claim-${index + 1}`;

    const planFieldMetadata = generateFieldMetadata({
      data: planData,
      section: plansSection,
      sectionName: plansSectionName,
      apiKey: SECTION_NAME.CASH_CLAIM_PLANS,
      program,
      filterFields,
    });

    newFieldTemplate[plansSectionName] = {
      ...plansSection,
      fields: planFieldMetadata["fields"],
    };
    newFieldToIdMap = {
      ...newFieldToIdMap,
      ...planFieldMetadata["fieldToIdMap"],
    };
    newInitializedData = {
      ...newInitializedData,
      ...planFieldMetadata["initializedData"],
    };

    // Cash Claim Actuals
    const actualsSectionName = generateMultiSectionKey({
      index: cashClaimKey,
      sectionName: SECTION_NAME.CASH_CLAIM_ACTUALS,
    });

    const actualsSection = schema[SECTION.CASH_CLAIM_ACTUALS];

    const actualsData =
      (cashRequest &&
        cashClaimKeys[index] &&
        cashRequest[cashClaimKeys[index]] &&
        cashRequest[cashClaimKeys[index]]["actual"]) ||
      {};

    const actualFieldMetadata = generateFieldMetadata({
      data: actualsData,
      section: actualsSection,
      sectionName: actualsSectionName,
      apiKey: SECTION_NAME.CASH_CLAIM_ACTUALS,
      program,
      filterFields,
    });

    newFieldTemplate[actualsSectionName] = {
      ...actualsSection,
      fields: actualFieldMetadata["fields"],
    };
    newFieldToIdMap = {
      ...newFieldToIdMap,
      ...actualFieldMetadata["fieldToIdMap"],
    };
    newInitializedData = {
      ...newInitializedData,
      ...actualFieldMetadata["initializedData"],
    };

    // Cash Claim Payment/Invoice
    const paymentSectionName = generateMultiSectionKey({
      index: cashClaimKey,
      sectionName: SECTION_NAME.CASH_CLAIM_INVOICE_PAYMENT,
    });

    const paymentSection = schema[SECTION.CASH_CLAIM_INVOICE_PAYMENT];

    const paymentData =
      (cashRequest &&
        cashClaimKeys[index] &&
        cashRequest[cashClaimKeys[index]] &&
        cashRequest[cashClaimKeys[index]]["invoice"]) ||
      {};

    const paymentFieldMetadata = generateFieldMetadata({
      data: paymentData,
      section: paymentSection,
      sectionName: paymentSectionName,
      apiKey: SECTION_NAME.CASH_CLAIM_INVOICE_PAYMENT,
      program,
      filterFields,
    });

    newFieldTemplate[paymentSectionName] = {
      ...actualsSection,
      fields: paymentFieldMetadata["fields"],
    };
    newFieldToIdMap = {
      ...newFieldToIdMap,
      ...paymentFieldMetadata["fieldToIdMap"],
    };
    newInitializedData = {
      ...newInitializedData,
      ...paymentFieldMetadata["initializedData"],
    };
  });

  return {
    fieldTemplate: newFieldTemplate,
    fieldToIdMap: newFieldToIdMap,
    initializedData: newInitializedData,
    numOfAdditionalSections: numOfCashClaims,
    cashClaims: cashClaims,
  };
};

const creditRequestApiToUiSectionDataAdapter = ({
  sectionTitle,
  program,
  data,
  schema,
  filterFields,
}) => {
  let initializedData = {};
  const section = schema[sectionTitle];

  const sectionName = section.name;

  const { fields, fieldToIdMap, initializedDataMd } = generateFieldMetadata({
    data,
    section,
    sectionName,
    program,
    filterFields,
  });

  let fieldTemplate = {
    [sectionName]: {
      ...section,
      fields,
    },
  };

  initializedData = {
    ...initializedDataMd,
    ...(data.creditRequest?.creditBudgetInfo?.budgetId && {
      budgetId: data.creditRequest.creditBudgetInfo.budgetId,
    }),
  };

  return {
    fieldTemplate,
    fieldToIdMap,
    initializedData,
  };
};

const defaultApiToUiSectionDataAdapter = ({
  sectionTitle,
  program,
  data,
  schema,
  filterFields,
}) => {
  const section = schema[sectionTitle];

  const sectionName = section.name;

  const { fields, fieldToIdMap, initializedData } = generateFieldMetadata({
    data,
    section,
    sectionName,
    program,
    filterFields,
  });

  let fieldTemplate = {
    [sectionName]: {
      ...section,
      fields,
    },
  };

  return {
    fieldTemplate,
    fieldToIdMap,
    initializedData,
  };
};

const generateFieldMetadata = ({
  data,
  section,
  sectionName,
  apiKey,
  program,
  filterFields,
}) => {
  let fields = [];
  const fieldToIdMap = {};
  const initializedData = {};
  const filteredFields = filterFields({
    section,
    program,
    hide: true,
  });

  if (!fieldToIdMap[sectionName]) {
    fieldToIdMap[sectionName] = {};

    fields = filteredFields.map((field) => {
      // Generate the data of the field.
      const id = uuid();
      const newField = {
        ...field,
        disabled: field.auto,
        id,
      };

      fieldToIdMap[sectionName][field.name] = id;

      //Initialize data
      initializedData[id] = UiValueAdapterFactory(field.component)({
        data,
        fieldName: field.name,
        fieldLabel: field.label,
        apiKey: apiKey ? apiKey : sectionName,
        type: field.type,
      });

      newField["required"] =
        newField.Required && newField.Required.includes(program);

      return newField;
    });
  }

  return {
    fields,
    fieldToIdMap,
    initializedData,
  };
};

export const ApiToUiSectionDataAdapterFactory = ({ sectionTitle }) => {
  switch (sectionTitle) {
    case SECTION.OPPORTUNITY_INFORMATION:
      return opportunityInformationApiToUiSectionDataAdapter;
    case SECTION.PROJECT_INFORMATION:
      return projectInformationApiToUiSectionDataAdapter;
    case SECTION.MIGRATION_WORKLOADS:
      return migrationWorkloadsApiToUiSectionDataAdapter;
    case SECTION.MIGRATION_PROGRAM_ENGAGEMENTS:
      return defaultApiToUiSectionDataAdapter;
    case SECTION.MIGRATION_DETAILS:
      return migrationDetailsApiToUiSectionDataAdapter;
    case SECTION.CASH_REQUEST_INFORMATION:
      return cashRequestApiToUiSectionDataAdapter;
    case SECTION.CREDIT_REQUEST_DETAILS:
      return creditRequestApiToUiSectionDataAdapter;
    default:
      return defaultApiToUiSectionDataAdapter;
  }
};
