import React, { useEffect, useState } from "react";
import {
  Cards,
  CollectionPreferences,
  Pagination,
  TextFilter,
} from "@amzn/awsui-components-react";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../../shared/util/services/data/DataService";
import { getPartnerScorecard } from "../../../../util/services/data/FundRequestService";
import { setErrorMessage } from "../../../../../shared/util/common/helper";
import { useCollection } from "@amzn/awsui-collection-hooks";
import TableEmptyState from "../../TableEmptyState";
import TableNoMatchState from "../../TableNoMatchState";
import WalletHeader from "./Header";
import { CARD_DEFINITIONS } from "./config";
import PropTypes from "prop-types";
import {
  buildMDFWalletfromCustomAndPrescriptive,
  removeInvalidWallets,
  formatWalletsForSpecialCases,
} from "./util";
import { isFeatureEnabled } from "shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";

const WalletCards = ({ variant = "container", setNotificationsItems }) => {
  const [budgets, setBudgets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    try {
      const [response, isSCAEnabled] = await Promise.all([
        await retryApiCall({ callApi: getPartnerScorecard() }),
        await isFeatureEnabled(FEATURE_FLAG_ENUMS.SCA_FUNDING_ENABLED),
      ]);

      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message,
          status: response.status,
        });
        setIsLoading(false);
        return;
      }

      if (response && Object.entries(response.wallets).length > 0) {
        let MDFInclusiveWallets = buildMDFWalletfromCustomAndPrescriptive(
          response.wallets,
          isSCAEnabled
        );
        let validWallets = removeInvalidWallets(MDFInclusiveWallets);
        let wallets = formatWalletsForSpecialCases(validWallets);
        setBudgets(wallets);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }, []);

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(budgets, {
      filtering: {
        empty: <TableEmptyState resourceName="Budgets" />,
        noMatch: (
          <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
        ),
      },
      pagination: { pageSize: 8 },
    });

  return (
    <Cards
      {...collectionProps}
      loading={isLoading}
      loadingText="Loading wallet..."
      variant={variant}
      cardsPerRow={[
        {
          cards: 1,
        },
        {
          minWidth: 500,
          cards: 2,
        },
        {
          minWidth: 800,
          cards: 4,
        },
      ]}
      items={items}
      cardDefinition={CARD_DEFINITIONS}
      header={<WalletHeader title={"My Wallet"} />}
      filter={
        <TextFilter {...filterProps} filteringPlaceholder="Find wallets" />
      }
      pagination={<Pagination {...paginationProps} disabled={isLoading} />}
      preferences={<CollectionPreferences preferences={{ pageSize: 6 }} />}
    />
  );
};

WalletCards.propTypes = {
  variant: PropTypes.string,
  setNotificationsItems: PropTypes.func,
};
export default WalletCards;
