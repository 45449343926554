import React from "react";
import { nanoid } from "nanoid";
import {
  CASH_CLAIM_STAGE,
  STAGE,
  STATUS,
} from "../../../../../shared/util/constants/fundRequestStatusType";
import Button from "@amzn/awsui-components-react/polaris/button";
import { openNewTab } from "../../../../../shared/util/common/helper";
import { isFeatureEnabled } from "../../../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../../../shared/util/constants/featureFlagValues";
import Config from "config";

const HINDER_FR_MESSAGE = Config?.HINDER_FR_MESSAGE
  ? Config.HINDER_FR_MESSAGE
  : "As of the December 15, 2024 claim deadline, we are no longer accepting claims for 2024 Fund Requests. For details regarding the deadline, please refer to the Partner Funding Guide or reaching out to your designated Partner Manager. You will be able to submit claims for 2025 Fund Requests in January 2025.";
export const CashClaimAction = ({
  cashClaimId,
  cashClaimStage,
  history,
  fundRequestId,
  stage,
  status,
  setNotificationsItems,
}) => {
  let action = "";
  const activeFundRequest =
    (status === STATUS.ACTIVE || status === STATUS.SUBMITTED) &&
    stage === STAGE.CASH_CLAIM;

  if (cashClaimStage === CASH_CLAIM_STAGE.ACTUAL && activeFundRequest) {
    action = (
      <Button
        variant="primary"
        onClick={async () => {
          const hinderFcSubmission = await isFeatureEnabled(
            FEATURE_FLAG_ENUMS.HINDER_FC_SUBMISSION
          );
          if (hinderFcSubmission) {
            setNotificationsItems([
              {
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => setNotificationsItems([]),
                header: "Error",
                content: HINDER_FR_MESSAGE,
                id: nanoid(10),
              },
            ]);
          } else {
            history.push(
              `/fund-request/${fundRequestId}/cashClaimActuals/${cashClaimId}/edit`,
              {
                claimId: cashClaimId,
              }
            );
          }
        }}
      >
        Submit Actual
      </Button>
    );
  }

  if (cashClaimStage === CASH_CLAIM_STAGE.INVOICE && activeFundRequest) {
    action = (
      <Button
        variant="primary"
        onClick={() => {
          openNewTab("https://payeecentral.amazon.com");
        }}
        iconAlign="right"
        iconName="external"
      >
        Submit Invoice
      </Button>
    );
  }

  return action;
};
