import React from "react";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import { isFeatureEnabled } from "shared/util/services/features/FeatureFlagsService";
import { getBudgets } from "external/util/services/data/FundRequestService.js";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "shared/util/services/data/DataService";
import { SCATableContainer } from "./Table";
import { PROGRAM_TYPE } from "shared/util/constants/programType";
import { PropTypes } from "prop-types";

export const SCATableSelector = ({
  data,
  dataInput,
  fieldTemplate,
  fundingTypes,
  enableSCA,
  setEnableSCA,
}) => {
  // State variables for SCA
  const [isSCAFlagEnabled, setIsSCAFlagEnabled] = React.useState(false);
  const [budgets, setBudgets] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSCAPartner, setIsSCAPartner] = React.useState(false);

  // For getBudgets query to fetch corresponding SCA budgets (data["program"] can be Sandbox, MDF etc)
  const fundingTemplates = [data["program"]];
  const budgetRecordTypes = ["SCA"];

  // Functions to verify if table should be enabled
  // Enables table if the partner is an SCA partner
  const getIfSCAPartner = () => {
    return !isLoading && isSCAFlagEnabled && isSCAPartner;
  };
  // Disables the table if the fund request is WWPS MDF, PIF or MAP
  const getIfProgramTypeAllowsSCA = () => {
    return (
      !(
        PROGRAM_TYPE.MDF.includes(data["program"]) && // WWPS MDF
        fieldTemplate.publicSectorDetails
      ) &&
      !PROGRAM_TYPE.PIF.includes(data["program"]) && //PIF
      !PROGRAM_TYPE.SMP.includes(data["program"])
    ); // MAP
  };

  // Check feature flag
  React.useEffect(async () => {
    try {
      setIsSCAFlagEnabled(
        await isFeatureEnabled(FEATURE_FLAG_ENUMS.SCA_FUNDING_ENABLED)
      );
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }, []);

  // Get budgets if SCA enabled
  React.useEffect(async () => {
    try {
      if (isSCAFlagEnabled) {
        const response = await retryApiCall({
          callApi: getBudgets({
            fundingTemplates,
            fundingTypes,
            budgetRecordTypes,
          }),
        });
        if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
          setIsLoading(false);
        } else if (
          response &&
          response.wallets &&
          Object.entries(response.wallets).length > 0
        ) {
          setBudgets(response.wallets);
          setIsSCAPartner(true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }, [isSCAFlagEnabled]);

  // Determine if the table should be enabled
  React.useEffect(() => {
    setEnableSCA(getIfSCAPartner() && getIfProgramTypeAllowsSCA());
  }, [isLoading, isSCAPartner]);

  // Displays corresponding components, the table or nothing
  if (!enableSCA) {
    return <></>;
  } else {
    return (
      <SCATableContainer data={data} dataInput={dataInput} budgets={budgets} />
    );
  }
};

SCATableSelector.propTypes = {
  data: PropTypes.object,
  dataInput: PropTypes.func,
  fieldTemplate: PropTypes.object,
  fundingTypes: PropTypes.arrayOf(PropTypes.string),
  enableSCA: PropTypes.bool,
  setEnableSCA: PropTypes.func,
};
