import DefaultSectionContainer from "./Steps/DefaultSectionContainer";
import { SECTION } from "../../../../shared/util/constants/section";
import CashRequestSectionContainer from "./Steps/DynamicMultiSection/CashRequestSectionContainer";
import ProjectInformationSectionContainer from "./Steps/ProjectInformationSectionContainer";
import MigrationDetailsSectionContainer from "./Steps/DynamicMultiSection/MigrationWorkloadsSectionContainer";
import CashClaimSectionContainer from "./Steps/CashClaimSectionContainer";
import OpportunityInformationSectionContainer from "./Steps/OpportunityInformationSectionContainer";
import CreditRequestSectionContainer from "./Steps/DynamicMultiSection/CreditRequestSectionContainer";

const SectionContainerFactory = ({ stepTitle }) => {
  switch (stepTitle) {
    case SECTION.PROJECT_INFORMATION:
      return ProjectInformationSectionContainer;
    case SECTION.MIGRATION_WORKLOADS:
      return MigrationDetailsSectionContainer;
    case SECTION.CASH_REQUEST_INFORMATION:
      return CashRequestSectionContainer;
    case SECTION.CASH_CLAIM_ACTUALS:
      return CashClaimSectionContainer;
    case SECTION.OPPORTUNITY_INFORMATION:
      return OpportunityInformationSectionContainer;
    case SECTION.CREDIT_REQUEST_DETAILS:
      return CreditRequestSectionContainer;
    default:
      return DefaultSectionContainer;
  }
};

export default SectionContainerFactory;
