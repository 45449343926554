export const STATUS = {
  DRAFT: "draft",
  ACTIVE: "active",
  EXPIRED: "expired",
  TERMINATED: "terminated",
  CANCELLED: "cancelled",
  SUBMITTED: "submitted",
  COMPLETED: "completed",
  DELETED: "deleted",
};

export const STAGE = {
  CREATED: "created",
  PARTNER: "partner",
  INVOICE: "invoice",
  SUBMITTED: "submitted",
  REVIEW: "review",
  TECH_APPROVAL: "tech_approval",
  BUSINESS_APPROVAL: "business_approval",
  FINANCE_APPROVAL: "finance_approval",
  PRE_APPROVAL: "pre_approval",
  CASH_CLAIM: "cash_claim",
  COMPLETED: "completed",
};

export const CASH_CLAIM_STAGE = {
  PLAN: "plan",
  ACTUAL: "actual",
  ACTUAL_APPROVAL: "actual_approval",
  INVOICE: "invoice",
  INVOICE_SUBMITTED: "invoice_submitted",
  COMPLETED: "completed",
  // map 2024
  PARTNER: "partner",
  NOT_CREATED: "not_created",
  CREATED: "created",
  CLAIM_APPROVAL: "claim_approval",
  CANCELLED: "cancelled",
};

export const CASH_CLAIM_STATUS = {
  ACTIVE: "active",
  LOCKED: "locked",
  UNLOCKED: "unlocked",
  CANCELLED: "cancelled",
  SUBMITTED: "submitted",
  COMPLETED: "completed",
};

export const isActive = (status) => {
  return status === STATUS.SUBMITTED || status === STATUS.ACTIVE;
};

export const FundRequestHistoryCategory = {
  EXTERNAL_APPROVAL: "external_approval",
  INTERNAL_APPROVAL: "internal_approval",
  ATTACHMENT: "attachment",
  FR_HISTORY: "fund_request_history",
};
