import React from "react";
import PropTypes from "prop-types";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { Button } from "@amzn/awsui-components-react";

const TableHeader = ({
  totalItemsCount,
  setSelectedItems,
  setSelectedBudget,
  updateBudget,
}) => {
  const tableName = "Strategic Collaboration Agreements";

  return (
    <Header
      counter={`(${totalItemsCount})`}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            onClick={() => {
              setSelectedItems([]);
              setSelectedBudget({});
              updateBudget(null);
            }}
          >
            Clear Selection
          </Button>
        </SpaceBetween>
      }
      description="Select an SCA agreement below (optional)"
    >
      {tableName}
    </Header>
  );
};

TableHeader.propTypes = {
  totalItemsCount: PropTypes.number,
  setSelectedItems: PropTypes.func,
  setSelectedBudget: PropTypes.func,
  updateBudget: PropTypes.func,
};

export default TableHeader;
