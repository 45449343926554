export const columnDefinitions = [
  {
    id: "name",
    header: "Agreement Name",
    cell: (item) => item.name,
    sortingField: "name",
  },
  {
    id: "allocation",
    header: "Allocation (USD)",
    cell: (item) => item.allocation,
    sortingField: "allocation",
  },
  {
    id: "utilized",
    header: "Utilized Amount (USD)",
    cell: (item) => item.utilized,
    sortingField: "utilized",
  },
  {
    id: "balance",
    header: "Balance (USD)",
    cell: (item) => item.balance,
    sortingField: "balance",
  },
  {
    id: "budgetStartDate",
    header: "Start Date",
    cell: (item) => item.budgetStartDate,
    sortingField: "budgetStartDate",
  },
  {
    id: "budgetEndDate",
    header: "End Date",
    cell: (item) => item.budgetEndDate,
    sortingField: "budgetEndDate",
  },
];

export const contentDisplay = [
  { id: "name", visible: true },
  { id: "allocation", visible: true },
  { id: "utilized", visible: true },
  { id: "balance", visible: true },
  { id: "budgetStartDate", visible: true },
  { id: "budgetEndDate", visible: true },
];
